import { FolderResponse, Folder } from "~/types/FolderStructure";

export const useFolderStructure = () => {
  const getMappedFolders = (folders: Array<FolderResponse>): Array<Folder> => {
    const mappedFolders = folders.map((folder) => {
      return {
        ...folder,
        title: folder.title ?? "Untitled",
      };
    });

    mappedFolders.sort((a, b) => a.id - b.id);

    return mappedFolders;
  };

  return {
    getMappedFolders,
  };
};
