import { defineStore } from "pinia";
import {
  FilesSortOrderEntry,
  Folder,
  FolderCategory,
  FolderResponse,
} from "~/types/FolderStructure";

export const useFolderStructureStore = defineStore({
  id: "folder-structure-store",
  state: () => {
    return {
      folders: [],
    } as {
      folders: Array<Folder>;
    };
  },
  actions: {
    async createFolder(dto: {
      title: string | null;
      clientId: number;
      category: FolderCategory;
    }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { folder: FolderResponse };
      }>("/folder-structure/create", {
        method: "POST",
        body: {
          title: dto.title === "" ? null : dto.title,
          clientId: dto.clientId,
          category: dto.category,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedFolders } = useFolderStructure();
        const folderStructure = getMappedFolders([data.value.data.folder])[0];
        this.folders.push(folderStructure);
        return { id: folderStructure.id, uuid: folderStructure.uuid };
      }
      return null;
    },

    async duplicateFolder(dto: { folderId: number; clientId: number }) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { folder: FolderResponse };
      }>(`folder-structure/duplicate`, {
        method: "POST",
        body: {
          clientId: dto.clientId,
          folderId: dto.folderId,
        },
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedFolders } = useFolderStructure();
        const folderStructure = getMappedFolders([data.value.data.folder])[0];
        this.folders.push(folderStructure);
        return { id: folderStructure.id, uuid: folderStructure.uuid };
      }
      return null;
    },

    async updateFolderMetadata(
      dto: { folderId: number } & Partial<{
        title: string;
        isOpen: boolean;
      }>,
    ) {
      const folderIndex = this.folders.findIndex(
        (folder) => folder.id === dto.folderId,
      );
      if (folderIndex !== -1) {
        this.folders.splice(folderIndex, 1, {
          ...this.folders[folderIndex],
          ...dto,
        });
      }
      const { error } = await useDatAdsApiFetch(
        `folder-structure/folder-metadata/${dto.folderId}`,
        {
          method: "PATCH",
          body: {
            title: dto.title,
            isOpen: dto.isOpen,
          },
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async updateFolderStructure(
      dto: Array<{
        folderId: number;
        folderSortOrder: number;
        files: FilesSortOrderEntry[];
      }>,
    ) {
      for (const folder of dto) {
        const folderIndex = this.folders.findIndex(
          (f) => f.id === folder.folderId,
        );
        if (folderIndex !== -1) {
          this.folders.splice(folderIndex, 1, {
            ...this.folders[folderIndex],
            folderSortOrder: folder.folderSortOrder,
          });
          for (const file of folder.files) {
            this.updateFileOfFolder({
              folderId: folder.folderId,
              file,
            });
          }
        }
      }
      const { error } = await useDatAdsApiFetch(
        `folder-structure/folder-structure`,
        {
          method: "PATCH",
          body: dto.map((folder) => ({
            folderId: folder.folderId,
            folderSortOrder: folder.folderSortOrder,
            files: folder.files.map((file) => ({
              id: file.id,
              type: file.type,
              fileSortOrder: file.fileSortOrder,
            })),
          })),
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      return null;
    },

    async deleteFolder(dto: { folderId: number }) {
      const { error } = await useDatAdsApiFetch(
        `folder-structure/${dto.folderId}`,
        {
          method: "DELETE",
        },
      );
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      const folderIndex = this.folders.findIndex(
        (folder) => folder.id === dto.folderId,
      );
      if (folderIndex !== -1) {
        this.folders.splice(folderIndex, 1);
      }
      return null;
    },

    async listFoldersOfClient(clientId: number) {
      const { data, error } = await useDatAdsApiFetch<{
        data: { folders: FolderResponse[] };
      }>(`folder-structure/client/${clientId}`, {
        method: "GET",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedFolders } = useFolderStructure();
        const folders = getMappedFolders(data.value.data.folders);
        for (const folder of folders) {
          const folderIndex = this.folders.findIndex((f) => f.id === folder.id);
          if (folderIndex === -1) {
            this.folders.push(folder);
          } else {
            this.folders.splice(folderIndex, 1, folder);
          }
        }
      }
      return null;
    },

    async listFoldersOfUser() {
      const { data, error } = await useDatAdsApiFetch<{
        data: { folders: FolderResponse[] };
      }>(`folder-structure`, {
        method: "GET",
      });
      if (error.value) {
        const errorMessage = useErrorHandler(error.value);
        return errorMessage;
      }
      if (data.value) {
        const { getMappedFolders } = useFolderStructure();
        const folders = getMappedFolders(data.value.data.folders);
        for (const folder of folders) {
          const folderIndex = this.folders.findIndex((f) => f.id === folder.id);
          if (folderIndex === -1) {
            this.folders.push(folder);
          } else {
            this.folders.splice(folderIndex, 1, folder);
          }
        }
      }
      return null;
    },

    updateFileOfFolder(dto: { folderId: number; file: FilesSortOrderEntry }) {
      if (dto.file.type === "creative_comparison_report") {
        const creativeComparisonStore = useCreativeComparisonStore();
        const reportIndex = creativeComparisonStore.reports.findIndex(
          (report) => report.id === dto.file.id,
        );
        if (reportIndex !== -1) {
          creativeComparisonStore.reports.splice(reportIndex, 1, {
            ...creativeComparisonStore.reports[reportIndex],
            folderId: dto.folderId,
            fileSortOrder: dto.file.fileSortOrder,
          });
        }
      } else if (dto.file.type === "creative_reporting_report") {
        const creativeReportingStore = useCreativeReportingStore();
        const reportIndex = creativeReportingStore.reports.findIndex(
          (report) => report.id === dto.file.id,
        );
        if (reportIndex !== -1) {
          creativeReportingStore.reports.splice(reportIndex, 1, {
            ...creativeReportingStore.reports[reportIndex],
            folderId: dto.folderId,
            fileSortOrder: dto.file.fileSortOrder,
          });
        }
      } else if (dto.file.type === "inspiration_board") {
        const inspirationBoardStore = useInspirationBoardStore();
        const boardIndex = inspirationBoardStore.boards.findIndex(
          (board) => board.id === dto.file.id,
        );
        if (boardIndex !== -1) {
          inspirationBoardStore.boards.splice(boardIndex, 1, {
            ...inspirationBoardStore.boards[boardIndex],
            folderId: dto.folderId,
            fileSortOrder: dto.file.fileSortOrder,
          });
        }
      } else if (dto.file.type === "testing_log_report") {
        const testingLogStore = useTestingLogStore();
        const reportIndex = testingLogStore.reports.findIndex(
          (report) => report.id === dto.file.id,
        );
        if (reportIndex !== -1) {
          testingLogStore.reports.splice(reportIndex, 1, {
            ...testingLogStore.reports[reportIndex],
            folderId: dto.folderId,
            fileSortOrder: dto.file.fileSortOrder,
          });
        }
      } else if (dto.file.type === "launch_report") {
        const launchReportStore = useLaunchReportStore();
        const reportIndex = launchReportStore.reports.findIndex(
          (report) => report.id === dto.file.id,
        );
        if (reportIndex !== -1) {
          launchReportStore.reports.splice(reportIndex, 1, {
            ...launchReportStore.reports[reportIndex],
            folderId: dto.folderId,
            fileSortOrder: dto.file.fileSortOrder,
          });
        }
      }
    },
  },
  getters: {
    getFoldersOfClient:
      (state) => (clientId: number, category: FolderCategory) => {
        return state.folders.filter(
          (folder) =>
            folder.clientId === clientId && folder.category === category,
        );
      },

    getFolderById: (state) => (folderId: number) => {
      return state.folders.find((folder) => folder.id === folderId) ?? null;
    },
  },
});

// Enable hot reloading when in development
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useFolderStructureStore, import.meta.hot),
  );
}
